import styles from './Slider.module.scss';
import classNames from 'classnames/bind';
import slide0 from '../../assets/image/slide0.jpg';
import slide1 from '../../assets/image/slide1.png';
import { Link } from 'react-router-dom';

const cx = classNames.bind(styles);

function Slider() {
    return (
        <div className={cx('container-slide')}>
            <div className={cx('gallery-display-area')}>
                <div className={cx('gallery-wrap')}>
                    <div className={cx('image')}>
                        <Link to="/khoa-hoc">
                            <img src={slide0} alt='slide0' />
                        </Link>
                    </div>
                    <div className={cx('image')}>
                        <Link to="/khoa-hoc">
                            <img src={slide1} alt='slide1' />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Slider;