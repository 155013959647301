import { createSlice } from '@reduxjs/toolkit';

const newOrderSlice = createSlice({
    name: 'listOrderSuccess',
    initialState: [],
    reducers: {
        addNewOrder: (state, action) => {
            state.push(action.payload);
        },
        removeAllOrder: () => [],
    },
});

export const { addNewOrder, removeAllOrder } = newOrderSlice.actions;

export default newOrderSlice.reducer;
