
import Slider from '../../Components/Sliders';
import styles from './Home.module.scss';
import classNames from "classnames/bind";
import React, { useRef, useState, useEffect } from 'react'; // Import useState
import { useDispatch, useSelector } from "react-redux";
import { getAllCourse } from "../../redux/apiRequest";
import { Link } from 'react-router-dom';

const cx = classNames.bind(styles);

const slideCourses = [
    {
        id: 1,
        title: 'Khóa học được yêu thích nhất',
        category: 'duoc-yeu-thich-nhat',
        despription: 'Best sellers năm 2019 với nhiều học viên nhất!',
        course: [],
    },
    {
        id: 2,
        title: 'Khóa học độc quyền',
        category: 'khoa-hoc-doc-quyen',
        despription: 'Trọn bộ khóa học được thiết kế và giảng dạy bởi chuyên gia hàng đầu, chỉ có tại Vietnamworks Learning',
    },
    {
        id: 3,
        title: 'Khóa học kỹ năng công việc',
        category: 'ky-nang-cong-viec',
        despription: 'Đầy đủ các khóa học kỹ năng văn phòng và kỹ năng mềm, từ cơ bản đến nâng cao dành riêng cho người đi làm',
    },
];
function Home() {
    const dispatch = useDispatch();
    let coursesList = [];
    let state = useSelector((state) => state)
    if (state.courses.courses && state.courses.courses.allCourses) {
        coursesList = state.courses.courses.allCourses;
    }
    const slidersRef = useRef([]); // Create an array of refs for each slider
    const cardWidth = 272;
    const [isPrevHidden, setIsPrevHidden] = useState([]); // Array of isPrevHidden states
    const [isNextHidden, setIsNextHidden] = useState([]); // Array of isNextHidden states

    const handleNext = (index) => {
        if (slidersRef.current[index]) {
            slidersRef.current[index].scrollLeft += cardWidth;
        }
    };

    const handlePrev = (index) => {
        if (slidersRef.current[index]) {
            slidersRef.current[index].scrollLeft -= cardWidth;
        }
    };

    const handleScroll = (index) => {
        if (slidersRef.current[index]) {
            setIsPrevHidden((prevState) =>
                prevState.map((prev, i) =>
                    i === index ? slidersRef.current[i].scrollLeft === 0 : prev
                )
            );
            setIsNextHidden((prevState) =>
                prevState.map((prev, i) =>
                    i === index
                        ? slidersRef.current[i].scrollLeft >= slidersRef.current[i].scrollWidth - slidersRef.current[i].clientWidth - 152
                        : prev
                )
            );
        }
    };

    useEffect(() => {
        slideCourses.forEach((_, index) => {
            if (slidersRef.current[index]) {
                slidersRef.current[index].addEventListener('scroll', () => handleScroll(index));
            }
        });

        return () => {
            slidersRef.current.forEach((ref, index) => {
                if (ref) {
                    ref.removeEventListener('scroll', () => handleScroll(index));
                }
            });
        };
    }, [slideCourses]);

    useEffect(() => {
        getAllCourse(dispatch);
    }, [])
    return (
        <div>
            <Slider />
            {slideCourses.map((slideCourse, index) => {
                let listCourseSlide = coursesList?.filter(course => course.category && course.category.includes(slideCourse.category)).slice(0, 7);

                return (
                    <div key={slideCourse.id} className={cx('wrapbody-content')} style={{ backgroundColor: index % 2 === 1 ? '#ffffff' : '#f6f6f6' }}>
                        <div className={cx('container-content')}>
                            <div className={cx('content-left')}>
                                <h3 className={cx('left-title')}>{slideCourse.title}</h3>
                                <p className={cx('left-text')}>{slideCourse.despription}</p>
                                <Link to="/khoa-hoc" className={cx('left-btn')}>Xem thêm</Link>
                            </div>
                            <div className={cx('content-right')}>
                                <div className={cx('gradient-overlay')}
                                    style={{
                                        // Thêm điều kiện cho background color của gradient-overlay
                                        backgroundImage:
                                            index % 2 === 0
                                                ? 'linear-gradient(to right, rgba(246, 246, 246, 0) 0%, rgb(246, 246, 246) 60%)'
                                                : 'linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 60%)'
                                    }}
                                ></div>
                                <div className={cx('card-slider')} ref={el => slidersRef.current[index] = el}>
                                    {listCourseSlide.map((course) => (
                                        <div key={course.id} className={cx('card')}>
                                            <Link to={'/thong-tin-khoa-hoc/' + course.id}>
                                                <div
                                                    className={cx('card-image')}
                                                    style={{ backgroundImage: `url(${course.courseImg})` }}
                                                ></div>
                                                <div className={cx('card-content')}>
                                                    <h4 className={cx('cardTitle')}>{course.courseName}</h4>
                                                    <p className={cx('cardInstructor')}>{course.lecturer.lecturerName}</p>
                                                </div>
                                                <div className={cx('card-content')}>
                                                    <h4 className={cx('cardPrice')}>{course.price} đ</h4>
                                                </div>
                                            </Link>
                                        </div>
                                    ))}

                                    <div className={cx('card-nav', 'card-nav-item')}>
                                        <Link to="/khoa-hoc">
                                            <div className={cx('card-content-nav')}>
                                                <h4 className={cx('cardTitle-nav')}>Xem thêm</h4>
                                                <h4 className={cx('cardTitle-nav')}>các khóa</h4>
                                                <h4 className={cx('cardTitle-nav')}>học khác</h4>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className={cx('half-card')}>
                                    </div>
                                </div>
                                <button
                                    className={cx('prev-btn', { hidden: isPrevHidden[index] })}
                                    onClick={() => handlePrev(index)}
                                >
                                    <i className={cx("fa-solid fa-chevron-left")}></i>
                                </button>
                                <button
                                    className={cx('next-btn', { hidden: isNextHidden[index] })}
                                    onClick={() => handleNext(index)}
                                >
                                    <i className={cx("fa-solid fa-chevron-right")}></i>
                                </button>
                            </div>
                        </div>
                    </div>
                )
            }
            )}

            <div className={cx('baner')}>
                <div className={cx('baner-bg')}>
                    <div className={cx('baner-content')}>
                    </div>
                    <div className={cx('baner-wrapper')}>
                        <h3 className={cx('baner-title')}>Giải pháp về thăng tiến sự nghiệp</h3>
                        <ul className={cx('baner-ul')}>
                            <li className={cx('baner-text')}><i className={cx('fa-solid fa-check')}></i>Cung cấp dữ liệu chi tiết về tất cả ngành nghề</li>
                            <li className={cx('baner-text')}><i className={cx('fa-solid fa-check')}></i>Lời khuyên kỹ năng giúp phát triển sự nghiệp</li>
                            <li className={cx('baner-text')}><i className={cx('fa-solid fa-check')}></i>Bài test hỗ trợ định hướng nghề nghiệp phù hợp</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;