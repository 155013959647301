import {createSlice} from "@reduxjs/toolkit";
const orderSlice = createSlice({
    name:"order",
    initialState:{
        order:{
            isFetching:false,
            error:false,
            success:false
        }
    },
    reducers:{
        oderStart:(state)=>{
            state.order.isFetching = true;
        },
        oderSuccess:(state)=>{
            state.order.isFetching = false;
            state.order.error = false;
            state.order.success = true;
        },
        oderFailed:(state)=>{
            state.order.isFetching = false;
            state.order.error = true;
            state.order.success = false;
        },
        
    }
})
export const {
    oderStart,
    oderSuccess,
    oderFailed,
} = orderSlice.actions;
export default orderSlice.reducer;