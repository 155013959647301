import Home from '../Pages/Home'
import Cart from '../Pages/Cart'
import Order from '../Pages/Order'
import CreateOrder from '../Pages/CreateOder'
import Payment from '../Pages/Payment'
import Login from '../Pages/Login'
import Courses from '../Pages/Courses'
import DetailsCourse from '../Pages/DetailsCourse'
import Register  from '../Pages/Register'

//Public Route
const publicRoutes = [
    { path: '/', component: Home },
    { path: '/khoa-hoc', component: Courses },
    { path: '/khoa-hoc/:category', component: Courses },
    { path: '/cart', component: Cart },
    { path: '/create-order', component: CreateOrder, layout: null  },
    { path: '/order', component: Order, layout: null  },
    { path: '/cart/payment', component: Payment},
    { path: '/login', component: Login, layout: null },
    { path: '/register', component: Register, layout: null },
    { path: '/thong-tin-khoa-hoc/:id', component: DetailsCourse },
];
const privateRoutes = [];
export { publicRoutes, privateRoutes }