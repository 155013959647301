import styles from './Payment.module.scss';
import React, { useState, useEffect } from "react";
import classNames from "classnames/bind";
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import { addCustomer,removeAllCustomers } from '../../redux/customerSlice';

const cx = classNames.bind(styles);

function formatNumber(Number) {
    return Number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

function calculateCourse(item) {
    let total = 0;
    for (let i = 0; i < item.length; i++) {
        total += 1;
    }
    return total;
}

function calculateTotal(cartItems) {
    return cartItems.reduce((total, item) => total + parseFloat(item.price), 0);
};
const Payment = () => {

    const user = useSelector((state) => state.auth.login?.currentUser);
    

    const dispatch = useDispatch();

    const sha256 = require('js-sha256');
    const cartItems = useSelector(state => state.cart);
    //Validate
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [nameError, setNameError] = useState("");
    const [phoneError, setPhoneError] = useState("");
    const [emailError, setEmailError] = useState("");
    
    const handleNameChange = (event) => {
        setName(event.target.value);
        setNameError("");
    };

    const handlePhoneChange = (event) => {
        setPhone(event.target.value);
        setPhoneError("");
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        setEmailError("");
    };
    //MEga PAY
    const domain = 'https://sandbox.megapay.vn';
    const [timeStamp, setTimeStamp] = useState('');
    let amount = calculateTotal(cartItems)
    const merId = 'EPAY000001'
    const encodeKey = "rf8whwaejNhJiQG2bsFubSzccfRc/iRYyGUn6SPmT6y/L7A2XABbu9y4GvCoSTOTpvJykFi6b1G0crU8et2O0Q=="
    const eventId = '9000000009';
    const merTrxId = merId + timeStamp + eventId + amount;
    const input_string = timeStamp + merTrxId + merId + amount + encodeKey
    const merchantToken = sha256(input_string);

    const handlePaymentClick = () => {
        if (!name) {
            setNameError("Vui lòng nhập Họ và tên.");
            return;
        }
        if (!phone) {
            setPhoneError("Vui lòng nhập Số điện thoại.");
            return;
        }
        if (!email) {
            setEmailError("Vui lòng nhập Email.");
            return;
        }
        else{
            dispatch(removeAllCustomers());
            dispatch(addCustomer({
                name: name,
                phone: phone,
                email: email,
            }));
            window.openPayment(1, domain);
        }
        
    };
    const invoiceNo = eventId + amount + timeStamp
    useEffect(() => {
        const formatTimeStamp = moment().format('YYYYMMddHHmmss');
        setTimeStamp(formatTimeStamp);
    }, []);
    useEffect(()=>{
        if (user) {
            setName(user.fullName);
            setEmail(user.eMail);
            setPhone(user.phoneNumber);
        }
    })

    return (
        <div >
            <ul className={cx('checkout-list-part')}>
                <li>
                    <span className={cx('checkout-span')}>Xem giỏ hàng</span>
                    <span className={cx('checkout-triangle-right')}></span>
                </li>

                <li>
                    <span className={cx('checkout-triangle-left')}></span>
                    <span className={cx('checkout-span')}>Điền thông tin và tiến hành thanh toán</span>
                    <span className={cx('checkout-triangle-right')}></span>
                </li>

                <li>
                    <span className={cx('checkout-triangle-left')}></span>
                    <span className={cx('checkout-span')}>Hoàn tất đơn hàng</span>
                </li>
            </ul>
            <div className={cx('checkout-form')}>
                <div className={cx('container-content')}>
                    <Form id="megapayForm" name="megapayForm" method="POST" className={cx('payment-form')} >
                        <div className={cx('buyer-info')}>
                            <h2>THÔNG TIN MUA HÀNG</h2>
                            <div className={cx('info-row')}>
                                <div className={cx('info-col')}>
                                    <h3>Họ và tên *</h3>
                                    <input
                                        type="text"
                                        className={cx('info-input')}
                                        name="buyerFirstNm"
                                        placeholder="Họ và Tên"
                                        value={name}
                                        onChange={handleNameChange}
                                    />
                                    {nameError && <span className="error-message">{nameError}</span>}
                                </div>
                                <div className={cx('info-col')}>
                                    <h3>Số điện thoại *</h3>
                                    <input
                                        type="text"
                                        className={cx('info-input')}
                                        name="buyerPhone"
                                        placeholder="Số điện thoại"
                                        value={phone}
                                        onChange={handlePhoneChange}
                                    />
                                    {phoneError && <span className="error-message">{phoneError}</span>}
                                </div>
                            </div>
                            <div className={cx('info-row')}>
                                <div className={cx('info-col')}>
                                    <h3>Email *</h3>
                                    <input
                                        type="text"
                                        className={cx('info-input')}
                                        name="buyerEmail"
                                        placeholder="Email"
                                        value={email}
                                        onChange={handleEmailChange}
                                    />
                                    {emailError && <span className="error-message">{emailError}</span>}
                                </div>
                            </div>
                        </div>
                        <input type="hidden" name="merId" value={merId} />
                        <input type="hidden" name="currency" value="VND" />
                        <input type="hidden" name="amount" value={amount} />
                        <input type="hidden" name="invoiceNo" value={invoiceNo} />
                        <input type="hidden" name="goodsNm" value='sanpham' />
                        <input type="hidden" name="payType" value="NO" />
                        <input type="hidden" name="callBackUrl" value="https://learningvietnamworks.pages.dev/create-order" />
                        <input type="hidden" name="notiUrl" value="https://learningvietnamworks.pages.dev/create-order" />
                        <input type="hidden" name="reqDomain" value="https://learningvietnamworks.pages.dev" />
                        <input type="hidden" name="fee" value="0" />
                        <input type="hidden" name="description" value="testsystem" />
                        <input type="hidden" name="userLanguage" value="VN" />
                        <input type="hidden" name="timeStamp" value={timeStamp} />
                        <input type="hidden" name="merTrxId" value={merTrxId} />
                        <input type="hidden" name="windowColor" value="#ef5459" />
                        <input type="hidden" name="windowType" value="0" />
                        <input type="hidden" name="merchantToken" value={merchantToken} />
                    </Form>

                    <div className={cx('wrap')}>
                        <h3>
                            <img src="https://cdn-skill.kynaenglish.vn/img/icon-mini-cart.png" alt="" />
                            <span className="color-green">{calculateCourse(cartItems)} khóa học</span>
                        </h3>
                        <ul >
                            {cartItems?.map((item, index) => (
                                <li key={index}>
                                    <p>- </p>
                                    <div>
                                        <p>{item.name}</p>
                                    </div>
                                    <div>
                                        <span className={cx('price')}>
                                            <b>{formatNumber(item.price)}đ</b>
                                        </span>
                                    </div>
                                </li>
                            ))}

                        </ul>
                        <div className={cx('coupon')}>
                            <p>Mã khuyến mãi (nhập mã và click Áp dụng)</p>
                            <div className={cx('form-coupon')}>
                                <input placeholder="Nhập mã khuyến mãi" />
                                <div>Áp dụng</div>
                            </div>
                        </div>
                        <div className={cx('total-coupon')}>
                            <ul>
                                <li>
                                    <span>Học phí gốc</span>
                                    <span><b>{formatNumber(calculateTotal(cartItems))}đ</b></span>
                                </li>
                                <li>
                                    <span>Tổng giảm giá</span>
                                    <span><b>0₫</b></span>
                                </li>
                                <li>
                                    <span style={{ color: '#fb6a00' }}><b>THÀNH TIỀN</b></span>
                                    <span style={{ color: '#fb6a00' }}><b>{formatNumber(calculateTotal(cartItems))}đ</b></span>
                                </li>
                            </ul>
                        </div>
                        <button onClick={handlePaymentClick} className={cx('submit-pay')}>HOÀN TẤT ĐƠN HÀNG</button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Payment;