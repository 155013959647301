import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { removeAllOrder } from "../../redux/newOrderSlice";

import { createOrder,sendMail } from "../../redux/apiRequest";

const Order = () => {
    const navigate = useNavigate();

    const dispatch = useDispatch();
    const customer = useSelector(state => state?.customer);
    const cartItems = useSelector(state => state?.cart);
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const resultCd = queryParams.get('resultCd');
    const invoiceNo = queryParams.get('invoiceNo');
    const amount = queryParams.get('amount');
    const transDt = queryParams.get('transDt');
    const transTm = queryParams.get('transTm');
    const year = transDt.slice(0, 4);
    const month = transDt.slice(4, 6) - 1;
    const day = transDt.slice(6, 8);
    const hour = transTm.slice(0, 2);
    const minute = transTm.slice(2, 4);
    const second = transTm.slice(4, 6);

    const targetTime = new Date(year, month, day, hour, minute, second);
    const millisecondsSinceEpoch = targetTime.getTime();
    let data = '';

    // Lấy giá trị milliseconds từ Epoch cho thời gian cần chuyển đổi
    async function setData() {
        const item = cartItems?.map((cartItem) => cartItem.name);
        if (customer) {
            return data = {
                "customerName": customer[0]?.name,
                "Date": parseFloat(millisecondsSinceEpoch),
                "email": customer[0]?.email,
                "item": item,
                "oderId": invoiceNo,
                "phoneNumber": customer[0]?.phone,
                "total": parseFloat(amount)
            }
        }
    }
    async function handleCreateOrder() {
        await setData();
        await sendMail(data, dispatch);
        await createOrder(data, dispatch ,navigate);
    }
    if (resultCd === "00_000") {
        handleCreateOrder();
    }
    else{
        dispatch(removeAllOrder());
        navigate("/order")
    }
};

export default Order;

