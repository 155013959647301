
import React from 'react';
import styles from './BreadCrumb.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

function BreadCrumb({ items }){
    return (
        <div className={cx('breadcrumb-container')}>
            <ul className={cx('breadcrumb-list')}>
                <i className={cx('fa-solid fa-house')}></i>
                {items.map((item, index) => (
                    <li key={index} className={cx('breadcrumb-item')}>
                        {index < items.length - 1 ? (
                            <>
                                <a href={item.link} className={cx('breadcrumb-link')}>
                                    {item.label}
                                </a>
                                <span className={cx('breadcrumb-separator')}>/</span>
                            </>
                        ) : (
                            <span className={cx('breadcrumb-current')}>{item.label}</span>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};
export default BreadCrumb;
