import styles from './Order.module.scss';
import classNames from "classnames/bind";
import { useSelector, useDispatch } from 'react-redux';
import { removeAllCustomers } from '../../redux/customerSlice';
import {Link } from "react-router-dom";
import { removeAllItemsFromCart } from '../../redux/cartSlice';
const cx = classNames.bind(styles);

function formatTimestamp(timestamp) {
    const date = new Date(timestamp);
    
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    
    return `${day}/${month}/${year} - ${hours}:${minutes}:${seconds}`;
}

function formatNumber(Number) {
    return Number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}
function isEmptyArray(arr) {
    return arr.length > 0;
}
const Order = () => {
    const dispatch = useDispatch();

    const dataOrder = useSelector((state) => state.listOrder[0]);
    if(isEmptyArray(useSelector((state) => state.listOrder))){
        dispatch(removeAllCustomers());
        dispatch(removeAllItemsFromCart())
    }
    return (
        <>
        {
            dataOrder?(
            <div className = { cx('cart-container') } >
        <div>
            <ul className={cx('checkout-list-part')}>
                <li>
                    <span className={cx('checkout-span')}>Xem giỏ hàng</span>
                    <span className={cx('checkout-triangle-right')}></span>
                </li>

                <li>
                    <span className={cx('checkout-triangle-left')}></span>
                    <span className={cx('checkout-span')}>Điền thông tin và tiến hành thanh toán</span>
                    <span className={cx('checkout-triangle-right')}></span>
                </li>

                <li>
                    <span className={cx('checkout-triangle-left')}></span>
                    <span className={cx('checkout-span')}>Hoàn tất đơn hàng</span>
                </li>
            </ul>
        </div>

        <h1>THANH TOÁN ĐƠN HÀNG THÀNH CÔNG</h1>
        <table className={styles.table}>
            <tbody>
                <tr>
                    <th>Khách hàng</th>
                    <td>{dataOrder?.customerName}</td>
                </tr>
                <tr>
                    <th>Mã đơn hàng</th>
                    <td>{dataOrder?.oderId}</td>
                </tr>
                <tr>
                    <th>Tên sản phẩm</th>
                    <td>{
                        dataOrder?.item.map((course, index) => (
                            <li key={index}>- {course}</li>
                        ))
                    }</td>
                </tr>
                <tr>
                    <th>Thời gian thanh toán</th>
                    <td>{formatTimestamp(dataOrder?.Date)}</td>
                </tr>
                <tr>
                    <th>Tổng đơn hàng</th>
                    <td>{formatNumber(dataOrder?.total)} đ</td>
                </tr>
                <tr>
                    <th>Mã khóa học</th>
                    <td>Mã kích hoạt khóa học sẽ được gửi vào email "{dataOrder?.email}" trong vòng 24h.</td>
                </tr>
            </tbody>
        </table>
        <Link to="/" className={cx("back-home")}><button>Trở về trang chủ</button></Link>
    </div >): (<div><h1>Đơn hàng không thành công!!!</h1><Link to="/cart" className={cx("back-home")}><h1> Về lại giỏ hàng</h1></Link></div>)
    }
    </> 
    );
};

export default Order;

