import styles from "./login.module.scss";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { loginUser } from "../../redux/apiRequest";
import { useDispatch } from "react-redux"
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);
const Login = () => {
    const [username, setUsename] = useState("");
    const [password, setPassword] = useState("");
    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const handleLogin = (e) => {
        e.preventDefault();
        const newUser = {
            userName: username,
            passWord: password,
        };
        loginUser(newUser, dispatch, navigate, handleLoginError)
    }
    const handleLoginError = (error) => {
        setErrors({ message: error.message });
    };
    return (
        <section className={cx("login-section")}>
            <div className={cx("login-container")}>
                <div className={cx("login-title")}> Log in</div>
                <form onSubmit={handleLogin}>
                    <label>USERNAME</label>
                    <input type="text"
                        id="username"
                        name="username"
                        onChange={(e) => setUsename(e.target.value)}
                        placeholder="Enter your username"
                    />
                    <span className={cx("register-error")}>{errors?.message}</span>
                    <label>PASSWORD</label>
                    <input type="password"
                        id="password"
                        name="password"
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter your password"
                    />
                    <button type="submit"> Continue </button>
                </form>
                <div className={cx("login-register")}> Don't have an account yet? </div>
                <Link className={cx("login-register-link")} to="/register">Register one for free </Link>
            </div>
        </section>
    );
}

export default Login;