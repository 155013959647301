import { createSlice } from "@reduxjs/toolkit";

const lectureSlice = createSlice({
    name:"lectures",
    initialState:{
        lectures:{
            allLectures: null,
            isFetching:false,
            error:false,
        }
    },
    reducers:{
        getLecturerStart:(state)=>{
            state.lectures.isFetching =true;
        },
        getLecturerSuccess:(state,action)=>{
            state.lectures.isFetching = false;
            state.lectures.allLectures = action.payload;
        },
        getLecturersFailed:(state)=>{
            state.lectures.isFetching = false;
            state.lectures.error = true;
        }
    }
})
export const {
    getLecturerStart,
    getLecturerSuccess,
    getLecturersFailed,
} = lectureSlice.actions;
export default lectureSlice.reducer;