import React from 'react';
import styles from './Footer.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const Footer = () => {
    return (
        <footer className={cx('footer')}>
            <div className={cx('container')}>
                <div className={cx('footer-content')}>
                    <div className={cx('footer-section')}>
                        <h3 className={cx('footer-heading')}>Copyright ©2018 VietnamWorks Learning. All Rights Reserved</h3>
                        <p className={cx('footer-text')}>
                            Một sản phẩm của <br />
                            Navigos Group <br />
                            Tầng 20, 11 Đoàn Văn Bơ, Phường 12, Quận 4, TP. Hồ Chí Minh <br />
                            Số điện thoại: 028 5404 4520<br />
                            Email: support@learning.vietnamworks.com<br />
                        </p>
                    </div>
                    <div className={cx('separator')}></div>
                    <div className={cx('footer-section')}>
                        <h3 className={cx('footer-heading')}>VietnamWorks Learning</h3>
                        <p className={cx('footer-text')}>
                            Giới thiệu<br />
                            Góc kiến thức<br />
                            Chính sách bảo mật<br />
                            Chính sách thanh toán<br />
                            Điều khoản sử dụng<br />
                            Câu hỏi thường gặp<br />
                            Hỗ trợ</p>
                    </div>
                    <div className={cx('separator')}></div>
                    <div className={cx('footer-section')}>
                        <h3 className={cx('footer-heading')}>Mạng xã hội</h3>
                        <ul className={cx('social-media-list')}>
                            <li className={cx('social-media-item')}>
                                <a href="#" className={cx('social-media-link-fb')}>
                                    <i className={cx('fa-brands fa-square-facebook')}></i>
                                </a>
                            </li>
                            <li className={cx('social-media-item')}>
                                <a href="#" className={cx('social-media-link-yt')}>
                                    <i className={cx('fa-brands fa-youtube')}></i>
                                </a>
                            </li>
                        </ul>
                        <h3 className={cx('footer-heading')}>Chấp nhận các loại thẻ</h3>
                        <ul className={cx('social-media-list')}>
                            <li className={cx('social-media-item')}>
                                <a href="#" className={cx('social-media-link-mt')}>
                                    <i className={cx('fa-brands fa-cc-mastercard')}></i>
                                </a>
                            </li>
                            <li className={cx('social-media-item')}>
                                <a href="#" className={cx('social-media-link-vs')}>
                                    <i className={cx('fa-brands fa-cc-visa')}></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
