import styles from './Cart.module.scss';
import classNames from "classnames/bind";

import { useDispatch, useSelector } from 'react-redux';
import { removeItemFromCart } from '../../redux/cartSlice';
import { Link } from 'react-router-dom';


const cx = classNames.bind(styles);


const Cart = () => {
    const dispatch = useDispatch();
    const cartItems = useSelector(state => state.cart);

    const  formatNumber= (Number) =>{
        return Number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    }

    const calculateCourse = (item) => {
        let total = 0;
        for (let i = 0; i < item.length; i++) {
            total += 1;
        }
        return total;
    }

    const calculateTotal = (cartItems) => {
        return cartItems.reduce((total, item) => total + parseFloat(item.price), 0);
    };

    const handleRemoveFromCart = (itemId) => {
        dispatch(removeItemFromCart(itemId));
    };

    return (
        <div className={cx('cart-container')}>
            <div className={cx('cart-header')}>
                <div className={cx('cart-left')}>
                    <i className={cx('fa-solid fa-cart-shopping')}></i>
                    <div className={cx('cart-description')}>
                        <p className={cx('cart-title')}>Thông tin giỏ hàng</p>
                        <div>
                            <p style={{ color: '#50ad4e', fontSize: '24px', fontWeight: 700 }}>{calculateCourse(cartItems)}</p>
                            <p style={{ color: '#50ad4e', fontSize: '24px', margin: '0 8px' }}>khóa học,</p>
                            <p style={{ color: '#fe9329', fontSize: '24px', fontWeight: 700 }}>{formatNumber(calculateTotal(cartItems))} đ</p>
                        </div>
                    </div>
                </div>
                <div className={cx('cart-right')}>
                    <Link to={'/cart/payment'} style={{ textTransform: 'uppercase' }} className={cx('checkout-btn')} >Thanh toán ngay</Link>
                </div>
            </div>
            <div className={cx('cart-content')}>
                {cartItems.map((item) => (
                    <div key={item.id} className={cx('cart-item')}>
                        <div className={cx('cart-content-left')}>
                            <img src={item.img} />
                            <div className={cx('description')}>
                                <p>{item.name}</p>
                                <button onClick={() => handleRemoveFromCart(item.id)} ><i className={cx('fa-solid fa-trash')}></i>Xóa khóa học</button>
                            </div>
                        </div>
                        <div className={cx('cart-content-right')}>
                            <p>{formatNumber(item.price)} đ</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Cart;