// cartSlice.js

import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
    name: 'cart',
    initialState: [],
    reducers: {
        addItemToCart: (state, action) => {
            state.push(action.payload);
        },
        removeItemFromCart: (state, action) => {
            return state.filter(item => item.id !== action.payload);
        },
        removeAllItemsFromCart: ()=>[]
    },
});

export const { addItemToCart, removeItemFromCart, removeAllItemsFromCart } = cartSlice.actions;

export default cartSlice.reducer;
