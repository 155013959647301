import styles from './DetailsCourse.module.scss';
import classNames from "classnames/bind";
import React, { useState, useEffect } from 'react';
import { getAllCourse } from "../../redux/apiRequest";
import { getAllLecturer } from "../../redux/apiRequest";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import { addItemToCart } from '../../redux/cartSlice';

const cx = classNames.bind(styles);

function countTotalTopics(lessons) {
    let totalTopics = 0;

    lessons.forEach((lesson) => {
        totalTopics += lesson.Topic.length;
    });
    return totalTopics;
}


const DetailsCourse = () => {
    //Cart
    const cartItems = useSelector(state => state.cart);

    //Course
    const { id } = useParams();
    let coursesList = [];
    let state = useSelector((state) => state)
    if (state.courses.courses && state.courses.courses.allCourses) {
        coursesList = state.courses.courses.allCourses;
    }

    let course = coursesList?.find(course => course.category && course.id.includes(id));
    let totalTopic = countTotalTopics(course.Lession);

    const decriptionLine = course.courseDecription.split('\n');
    const benefitLine = course.benefit.split('\n');
    const subjectsLine = course.subjects.split('\n');
    const requireLine = course.require.split('\n');

    const dispatch = useDispatch();

    const [selectedCourse, setSelectedCourse] = useState(null);
    const [seeMore, setSeeMore] = useState(false);
    //Add to cart
    const handleAddToCart = () => {
        const isItemInCart = cartItems?.some((item) => item.id === course.id);
        if (isItemInCart) {
            alert('Sản phẩm đã tồn tại trong giỏ hàng');
        } else {
            dispatch(addItemToCart({
                id: course.id,
                name: course.courseName,
                img: course.courseImg,
                price: course.price
            }));
            alert('Thêm sản phẩm vào giỏ hàng thành công');
        }
    };

    //Lecturers
    let lecturerList = [];
    let lecture = [];
    let lectureLine = [];
    if (state.lecturers.lectures && state.lecturers.lectures.allLectures) {
        lecturerList = state.lecturers.lectures.allLectures;
        lecture = lecturerList?.filter(lecture => lecture.id && lecture.id.includes(course.lecturer.id));
        lectureLine = lecture[0].lecturerDescription.split('\n');
    }
    // Xem thêm
    const handleSeeMore = () => {
        setSeeMore(!seeMore);
    };

    // Click Lession
    const handleCourseClick = (courseId) => {
        setSelectedCourse(selectedCourse === courseId ? null : courseId);
    };
    useEffect( () => {
        getAllCourse(dispatch);
        getAllLecturer(dispatch);
    }, [])
    return (
        <div className={cx('detailCourses-container')}>
            <section className={cx('detailTop')}>
                <div className={cx('detail-top-left')}>
                    <h1>{course.courseName}</h1>
                    {course.someDetail.map((detail, index) => (
                        <p key={index}><i className={cx('fa-solid fa-star')}></i>{detail}</p>
                    ))}

                </div>
                <div className={cx('detail-top-right')}>
                    <div className={cx('description')}>
                        <div className={cx('description-image')} style={{ backgroundImage: `url(${course.courseImg})` }}></div>
                        <div className={cx('description-courses')}>
                            <h1 className={cx('highlight')}>{course.price} đ</h1>
                            <p className={cx('highlight')}><i className={cx('fa-solid fa-tv')}></i>Học online</p>
                            <p><i className={cx('fa-regular fa-clock')}></i>22 giờ 6 phút</p>
                            <p><i className={cx('fa-brands fa-youtube')}></i>{totalTopic} Video</p>
                            <p><i className={cx('fa-solid fa-earth-americas')}></i>Tiếng Việt</p>
                            <p><i className={cx('fa-regular fa-thumbs-up')}></i>Cam kết hài lòng 100%</p>
                        </div>
                        <button onClick={handleAddToCart} className={cx('buy-btn')}>Mua Ngay</button>
                    </div>
                </div>
            </section>
            <main>
                <div className={cx('course-content')}>
                    <h1>Nội dung khóa học</h1>
                    <div className={cx('title')}><i className={cx('fa-brands fa-youtube')}></i>Video</div>
                    <div className={cx('course')}>
                        {course.Lession.map((course, index) => (
                            <div key={index} className={cx('course-item')}>
                                <div className={cx('course-title')} onClick={() => handleCourseClick(course.id)}>
                                    {course.LessionsName}
                                </div>
                                {selectedCourse === course.id && (
                                    <div className={cx('lessons')}>
                                        {course.Topic.map((lesson, index) => (
                                            <div key={index} className={cx('lesson')}>
                                                {lesson}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
                <div className={cx('knowledge')}>
                    <h1>Tôi sẽ học gì?</h1>
                    <div className={cx("what-will-learn-block")}>
                        <div className={cx("what-will-learn-details")}>
                            {decriptionLine.map((decription, index) => (<p className={cx('font-size18')} key={index} >{decription}</p>))}

                            {seeMore && (
                                <>
                                    <p style={{ textAlign: 'justify', marginBottom: '8px' }}>
                                        <span style={{ fontSize: 'medium' }}><strong>* LỢI ÍCH HỌC VIÊN</strong></span>
                                    </p>
                                    {benefitLine.map((benefit, index) => (
                                        <p className={cx('font-size18')} key={index}>
                                            {benefit}
                                        </p>
                                    ))}

                                    <p style={{ textAlign: 'justify', marginBottom: '8px' }}>
                                        <span style={{ fontSize: 'medium' }}><strong>* ĐỐI TƯỢNG HỌC VIÊN</strong></span>
                                    </p>
                                    {subjectsLine.map((subject, index) => (
                                        <p className={cx('font-size18')} key={index}>
                                            {subject}
                                        </p>
                                    ))}
                                    <p style={{ textAlign: 'justify', marginBottom: '8px' }}>
                                        <span style={{ fontSize: 'medium' }}><strong>* YÊU CẦU KHÓA HỌC</strong></span>
                                    </p>
                                    {requireLine.map((require, index) => (
                                        <p className={cx('font-size18')} key={index}>
                                            {require}
                                        </p>
                                    ))}
                                </>
                            )}
                        </div>
                        <div className={cx("see-more-content-details")}>
                            <button onClick={handleSeeMore} className={cx("btn-see-more-content")}>
                                {seeMore ? '- Thu nhỏ' : '+ Xem hết'}
                            </button>
                        </div>
                    </div>
                </div>
                <div className={cx('lecturers')}>
                    <h1>Thông tin giảng viên</h1>
                    {lecture.map((lecture, index) => (
                        <div key={index} className={cx('lecturers-wrap')}>
                            <div className={cx('lecturers-avt')}>
                                <img className={cx('lecturers-img')} src={lecture.lectureImg} />
                                <p className={cx('lecturers-name')}>{lecture.lectureName}</p>
                            </div>
                            <div className={cx('lecturers-description')}>
                                <h3>* Kinh nghiệm và thành tựu</h3>
                                {lectureLine.map((lecDecription, index) => (
                                    <p key={index}>
                                        {lecDecription}
                                    </p>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </main>
        </div>
    );
};

export default DetailsCourse;