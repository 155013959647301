import styles from './NavCategory.module.scss';
import { Link } from 'react-router-dom';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

function NavCategory() {
    return (
        <div className={cx('nav-category')}>
            <ul className={cx('subnav-category')}>
                <li>
                    <Link to="/khoa-hoc/khoa-hoc-doc-quyen">
                        <i className={cx('fa-solid fa-book')} style={{ color: '#2d5dd2' }}></i>Khóa Học Độc Quyền
                    </Link>
                </li>
                <li>
                    <Link to="/khoa-hoc/kinh-doanh">
                        <i className={cx('fa-solid fa-briefcase')} style={{ color: '#2d5dd2' }}></i>Kinh Doanh
                    </Link>
                </li>
                <li>
                    <Link to="/khoa-hoc/ky-nang-cong-viec">
                        <i className={cx('fa-regular fa-heart')} style={{ color: '#2d5dd2' }}></i>Kỹ Năng Công Việc
                    </Link>
                </li>
                <li>
                    <Link to="/khoa-hoc/marketing">
                        <i className={cx('fa-solid fa-volume-low')} style={{ color: '#2d5dd2' }}></i>Marketing
                    </Link>
                </li>
                <li>
                    <Link to="/khoa-hoc/cong-nghe-thong-tin">
                        <i className={cx('fa-solid fa-tv')} style={{ color: '#2d5dd2' }}></i>Công Nghệ Thông Tin
                    </Link>
                </li>
                <li>
                    <Link to="/khoa-hoc/my-thuat-da-phuong-tien">
                        <i className={cx('fa-solid fa-paintbrush')} style={{ color: '#2d5dd2' }}></i>Mỹ Thuật Đa Phương Tiện
                    </Link>
                </li>
                <li>
                    <Link to="/khoa-hoc/ngoai-ngu">
                        <i className={cx('fa-regular fa-comment')} style={{ color: '#2d5dd2' }}></i>Ngoại Ngữ
                    </Link>
                </li>
                <li>
                    <Link to="/khoa-hoc/tai-chinh">
                        <i className={cx('fa-solid fa-money-bill')} style={{ color: '#2d5dd2' }} ></i>Tài chính
                    </Link>
                </li>
                <li>
                    <Link to="/khoa-hoc/quan-tri-nhan-su">
                        <i className={cx('fa-regular fa-user')} style={{ color: '#2d5dd2' }}></i>Quản Trị Nhân Sự
                    </Link>
                </li>
            </ul>
        </div>
    )
}
export default NavCategory;