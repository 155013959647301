import Header  from "../components/Header";
import NavCategory  from "../components/NavCategory";
import Footer  from "../components/Footer";
// import Navbar from "./Navbar";
import styles from './DefaultLayout.module.scss';
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

function DefaultLayout({children}) {
    return (
        <div className={cx('wrapper')}>
            <Header/>
            <NavCategory/>
            {children}
            <Footer/>
        </div>
    );
}

export default DefaultLayout;