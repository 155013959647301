import { createSlice } from "@reduxjs/toolkit";

const courseSlice = createSlice({
    name:"courses",
    initialState:{
        courses:{
            allCourses: null,
            isFetching:false,
            error:false,
        }
    },
    reducers:{
        getCourseStart:(state)=>{
            state.courses.isFetching =true;
        },
        getCourseSuccess:(state,action)=>{
            state.courses.isFetching = false;
            state.courses.allCourses = action.payload;
        },
        getCoursesFailed:(state)=>{
            state.courses.isFetching = false;
            state.courses.error = true;
        }
    }
})
export const {
    getCourseStart,
    getCourseSuccess,
    getCoursesFailed,
} = courseSlice.actions;
export default courseSlice.reducer;