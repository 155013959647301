import styles from './Courses.module.scss';
import BreadCrumb from '../../Components/BreadCrumb';
import { getAllCourse } from "../../redux/apiRequest";

import classNames from "classnames/bind";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const cx = classNames.bind(styles);
const Courses = () => {
    const dispatch = useDispatch();
    const { category } = useParams();

    useEffect(() => {
        getAllCourse(dispatch);
    }, [])
    let breadcrumbNav
    switch (category) {
        case 'khoa-hoc-doc-quyen': breadcrumbNav = 'Khóa học độc quyền'; break;
        case 'kinh-doanh': breadcrumbNav = 'Kinh Doanh'; break;
        case 'ky-nang-cong-viec': breadcrumbNav = 'Kỹ Năng Công Việc'; break;
        case 'marketing': breadcrumbNav = 'Marketing'; break;
        case 'cong-nghe-thong-tin': breadcrumbNav = 'Công Nghệ Thông Tin'; break;
        case 'my-thuat-da-phuong-tien': breadcrumbNav = 'Mỹ Thuật Đa Phương Tiện'; break;
        case 'ngoai-ngu': breadcrumbNav = 'Ngoại Ngữ'; break;
        case 'tai-chinh': breadcrumbNav = 'Tài Chính'; break;
        case 'quan-tri-nhan-su': breadcrumbNav = 'Quản Trị Nhân Sự'; break;
        default:
            breadcrumbNav ='';
    }

    const breadcrumbData = [
        { label: 'Trang chủ', link: '/' },
        { label: 'Khóa học', link: '/khoa-hoc' },
        { label: breadcrumbNav },
    ];

    let coursesList = [];
    let state = useSelector((state) => state)
    if (state.courses.courses && state.courses.courses.allCourses) {
        coursesList = state.courses.courses.allCourses;
    }
    
    let filteredCourses;
    if (category) {
        filteredCourses = coursesList.filter(course => course.category && course.category.includes(category));
    }
    else {
        filteredCourses = coursesList
    }
    const numberOfElements = filteredCourses.length;
    const itemsPerPage = 12; // Số lượng item trên mỗi trang
    const totalPages = Math.ceil(filteredCourses.length / itemsPerPage); // Tính tổng số trang
    const [currentPage, setCurrentPage] = useState(1); // Trang hiện tại

    // Tính index của item đầu tiên và item cuối cùng của trang hiện tại
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = Math.min(startIndex + itemsPerPage, filteredCourses.length);

    // Lấy danh sách các item của trang hiện tại
    const currentItems = filteredCourses.slice(startIndex, endIndex);

    // Hàm chuyển trang
    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    
    return (
        <div className={cx('courses-container')}>
            <BreadCrumb items={breadcrumbData} />
            <div className={cx('courses-sales')}>
                <h1>{numberOfElements}</h1><p>Khóa học</p><p>chất lượng giá tốt</p>
            </div>
            <div className={cx('course-grid')}>
                {currentItems.map((course) => (
                    <div key={course.id} className={cx('course-card')}>
                        <Link to={'/thong-tin-khoa-hoc/' + course.id}>
                            <div style={{ backgroundImage: `url(${course.courseImg})` }} alt={course.courseName} className={cx('course-image')}></div>
                            <div className={cx('content')}>
                                <div className={cx('content-top')}>
                                    <h3 className={cx('course-title')}>{course.courseName}</h3>
                                    <p className={cx('course-instructor')}>Giảng viên: {course.lecturer.lecturerName}</p>
                                </div>
                                <p className={cx('course-price')}>{course.price} đ</p>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>

            {/* Phân trang */}
            <div className={cx('pagination')}>
                {Array.from({ length: totalPages }).map((_, index) => (
                    <button
                        key={index}
                        className={cx('page-link', { active: index + 1 === currentPage })}
                        onClick={() => handlePageChange(index + 1)}
                    >
                        {index + 1}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default Courses;