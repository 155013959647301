import { useState } from "react";
import styles from "./register.module.scss";
import { registerUser } from "../../redux/apiRequest";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);
const Register = () => {
    const [username, setUsename] = useState("");
    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [fullname, setFullname] = useState("");
    const [phonenumber, setPhoneNumber] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [errors, setErrors] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const handleRegister = (e) => {
        e.preventDefault();
        if (validateForm()) {
            const newUser = {
                eMail: email,
                fullName: fullname,
                phoneNumber: phonenumber,
                userName: username,
                passWord: password
            };
            registerUser(newUser, dispatch, navigate, handleRegisterError)
        }
    };
    const handleRegisterError = (error) => {
        setErrors({ username: error.message });
    };
    const validateForm = () => {
        const errors = {};

        if (username.trim() === '') {
            errors.username = 'Username is required';
        } else if (username.length < 8 || username.length > 20) {
            errors.username = 'Username must be 8-20 characters long';
        }

        if (password.trim() === '') {
            errors.password = 'Password is required';
        } else if (password.length < 8 || password.length > 24) {
            errors.password = 'Password must be 8-24 characters long';
        }
        if (confirmPassword.trim() === '') {
            errors.confirmPassword = 'Confirm Password is required';
        } else if (confirmPassword !== password) {
            errors.confirmPassword = 'Passwords do not match';
        }
        setErrors(errors);

        return Object.keys(errors).length === 0;
    };
    return (
        <section className={cx("register-section")}>
            <div className={cx("register-container")}>
                <div className={cx("register-title")}> Sign up </div>
                <form className={cx("form-register")} onSubmit={handleRegister}>
                    <label>TÊN ĐĂNG NHẬP</label>
                    <input
                        type="text"
                        placeholder="Enter your username"
                        onChange={(e) => setUsename(e.target.value)}
                    />
                    <span className={cx("register-error")}>{errors.username}</span>
                    <label>EMAIL</label>
                    <input
                        type="text"
                        placeholder="Enter your email"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <span className={cx("register-error")}>{errors.email}</span>
                    <label>SỐ ĐIỆN THOẠI</label>
                    <input
                        type="text"
                        placeholder="Enter your phone number"
                        onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                    <span className={cx("register-error")}>{errors.phonenumber}</span>
                    <label>HỌ VÀ TÊN</label>
                    <input
                        type="text"
                        placeholder="Enter your fullname"
                        onChange={(e) => setFullname(e.target.value)}
                    />
                    <span className={cx("register-error")}>{errors.fullname}</span>
                    <label>PASSWORD</label>
                    <input
                        type="password"
                        placeholder="Enter your password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <span className={cx("register-error")}>{errors.password}</span>
                    <label>CONFIRM PASSWORD</label>
                    <input
                        type="password"
                        placeholder="Enter your password"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <span className={cx("register-error")}>{errors.confirmPassword}</span>
                    <button type="submit"> Create account </button>
                </form>
            </div>
        </section>

    );
}

export default Register;