import {createSlice} from "@reduxjs/toolkit";
const mailSlice = createSlice({
    name:"mail",
    initialState:{
        sendmail:{
            isFetching:false,
            error:false,
            success:false
        }
    },
    reducers:{
        sendMailStart:(state)=>{
            state.sendmail.isFetching = true;
        },
        sendMailSuccess:(state)=>{
            state.sendmail.isFetching = false;
            state.sendmail.error = false;
            state.sendmail.success = true;
        },
        sendMailFailed:(state)=>{
            state.sendmail.isFetching = false;
            state.sendmail.error = true;
            state.sendmail.success = false;
        },
    }
})
export const {
    sendMailStart,
    sendMailSuccess,
    sendMailFailed
} = mailSlice.actions;
export default mailSlice.reducer;