import styles from './Header.module.scss';
import classNames from 'classnames/bind';
import { Link, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../../../redux/apiRequest";
import { createAxios } from "../../../../createInstance";
import { logoutSuccess } from "../../../../redux/authSlice";

const cx = classNames.bind(styles);

function Header() {
    const user = useSelector((state) => state.auth.login.currentUser);
    const accessToken = user?.accessToken;
    const id = user?._id;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let axiosJWT = createAxios(user, dispatch, logoutSuccess);
    const handleLogout = () => {
        logOut(dispatch, id, navigate, accessToken, axiosJWT)
    }
    return (
        <header className={cx('container')}>
            <div className={cx('header')}>
                <ul className={cx('nav')}>
                    <li className={cx('li-logo')}>
                        <a href="/">
                            <img src="https://s3-ap-southeast-1.amazonaws.com/learning-media.vietnamworks.com/logo/stores/1/vnw_learning.png" />
                        </a>
                    </li>
                    <li><i className={cx('fa-solid fa-star')} /></li>
                    <li>
                        <a className={cx('a-text')}>
                            Lĩnh vực
                        </a>
                        <div className={cx('subnav-wrap')}>
                            <i className={cx("angle-up-show fa-sharp fa-solid fa-angle-up")}></i>
                            <ul className={cx('subnav')}>
                                <li>
                                    <Link to="/khoa-hoc">Tất cả khóa học</Link>
                                    <i className={cx("fa-solid fa-angle-right")}></i>
                                </li>
                                <li>
                                    <Link to="/khoa-hoc/khoa-hoc-doc-quyen">
                                        <i className={cx('fa-solid fa-book')} style={{ color: '#0e46cd' }}></i>Khóa Học Độc Quyền
                                    </Link>
                                    <i className={cx("fa-solid fa-angle-right")}></i>
                                </li>
                                <li>
                                    <Link to="/khoa-hoc/kinh-doanh">
                                        <i className={cx('fa-solid fa-briefcase')} style={{ color: '#0e46cd' }}></i>Kinh Doanh
                                    </Link>
                                    <i className={cx("fa-solid fa-angle-right")}></i>
                                </li>
                                <li>
                                    <Link to="/khoa-hoc/ky-nang-cong-viec">
                                        <i className={cx('fa-regular fa-heart')} style={{ color: '#0e46cd' }}></i>Kỹ Năng Công Việc
                                    </Link>
                                    <i className={cx("fa-solid fa-angle-right")}></i>
                                </li>
                                <li>
                                    <Link to="/khoa-hoc/marketing">
                                        <i className={cx('fa-solid fa-volume-low')} style={{ color: '#0e46cd' }}></i>Marketing
                                    </Link>
                                    <i className={cx("fa-solid fa-angle-right")}></i>
                                </li>
                                <li>
                                    <Link to="/khoa-hoc/cong-nghe-thong-tin">
                                        <i className={cx('fa-solid fa-tv')} style={{ color: '#0e46cd' }}></i>Công Nghệ Thông Tin
                                    </Link>
                                    <i className={cx("fa-solid fa-angle-right")}></i>
                                </li>
                                <li>
                                    <Link to="/khoa-hoc/my-thuat-da-phuong-tien">
                                        <i className={cx('fa-solid fa-paintbrush')} style={{ color: '#0e46cd' }}></i>Mỹ Thuật Đa Phương Tiện
                                    </Link>
                                    <i className={cx("fa-solid fa-angle-right")}></i>
                                </li>
                                <li>
                                    <Link to="/khoa-hoc/ngoai-ngu">
                                        <i className={cx('fa-regular fa-comment')} style={{ color: '#0e46cd' }}></i>Ngoại Ngữ
                                    </Link>
                                    <i className={cx("fa-solid fa-angle-right")}></i>
                                </li>
                                <li>
                                    <Link to="/khoa-hoc/tai-chinh">
                                        <i className={cx('fa-solid fa-money-bill')} style={{ color: '#0e46cd' }} ></i>Tài chính</Link>
                                    <i className={cx("fa-solid fa-angle-right")}></i>
                                </li>
                                <li>
                                    <Link to="/khoa-hoc/quan-tri-nhan-su"><i className={cx('fa-regular fa-user')} style={{ color: '#0e46cd' }}></i>Quản Trị Nhân Sự
                                    </Link>
                                    <i className={cx("fa-solid fa-angle-right")}></i>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <li>
                        <form action='' className={cx('search-box')}>
                            <input type='text' className={cx('search-text')} placeholder='Tìm kiếm' />
                            <button className={cx('search-btn')}><i className={cx("fa-solid fa-magnifying-glass")}></i></button>
                        </form>
                    </li>
                    <li>
                        <Link to="/cart" style={{ textDecoration: "none" }}>
                            <div className={cx('cart')}>
                                Go<i className={cx("fa-solid fa-cart-shopping")}></i>
                            </div>
                        </Link>
                    </li>
                </ul>
                {user ? (
                    <div className={cx('login-box')}>
                        <div className={cx('userName')}>
                            Hi, <span>{user.fullName}</span>
                        </div>
                        <button onClick={handleLogout} className={cx('login-btn')}>
                            <p>Log out</p>
                        </button>
                    </div>
                ) : (
                    <div className={cx('login-box')}>
                        <Link to="/login" style={{ textDecoration: "none" }}>
                            <button className={cx('login-btn')}>
                                <i className={cx("fa-solid fa-person-walking")}></i>
                                <p>Đăng nhập với Vietnamworks</p>
                            </button>
                        </Link>
                    </div>
                )}

            </div>
        </header >
    )
}

export default Header;