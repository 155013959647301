import axios from "axios";
import { RegisterFailed, RegisterStart, RegisterSuccess, loginFailed, loginStart, loginSuccess, logoutFailed, logoutStart, logoutSuccess } from "./authSlice";
import { getCourseStart, getCourseSuccess, getCoursesFailed} from "./courseSlice";
import { getLecturerStart, getLecturerSuccess, getLecturersFailed} from "./lecturerSlice";
import {oderStart,oderSuccess,oderFailed } from "./orderSlice"
import {sendMailStart, sendMailSuccess, sendMailFailed} from "./mailSlice"
import { addNewOrder, removeAllOrder } from "./newOrderSlice";

axios.defaults.baseURL = 'https://thienho.gcalls.vn';
export const sendMail = async(order,dispatch)=>{
    await dispatch(sendMailStart());
    try {
        await axios.post("/auth/create-mail", order);
        await dispatch(sendMailSuccess());
    }
    catch (err) {
        await dispatch(sendMailFailed(err));
    }
}
export const loginUser = async (user, dispatch, navigate,errorCallback) => {
    await dispatch(loginStart());
    try {
        const res = await axios.post("/auth/login", user);
        await dispatch(loginSuccess(res.data));
        await navigate("/");
    }
    catch (error) {
        if (error.response && error.response.data && error.response.data.message !== "") {
            await errorCallback(new Error("UserName or password is incorrect"));
            await dispatch(loginFailed());
        }
    }
}
export const logOut = async (dispatch, id, navigate, accessToken, axiosJWT) => {
    await dispatch(logoutStart());
    try {
        await axiosJWT.post("/auth/logout", id, {
            headers: { token: `Bearer ${accessToken}` },
        });
        await dispatch(logoutSuccess());
        await navigate("/");
    }
    catch (err) {
        await dispatch(logoutFailed());
    }
}


export const registerUser = async (user, dispatch, navigate,errorCallback) => {
    await dispatch(RegisterStart());
    try {
        await axios.post("/auth/register", user)
        await dispatch(RegisterSuccess());
        await navigate("/login");
    } catch (error) {
        if (error.response && error.response.data && error.response.data.message !== "") {
            await errorCallback(new Error("Username already exists"));
            await dispatch(RegisterFailed(error));
        }
    }
}
export const createOrder = async(order,dispatch,navigate)=>{
    await dispatch(oderStart());
    try {
        await axios.post("/api/lark-data/order", order);
        await dispatch(oderSuccess());
        await dispatch(removeAllOrder());
        await dispatch(addNewOrder(order))
        await navigate("/order");
    }
    catch (err) {
        await dispatch(oderFailed(err));
    }
}
export const getAllCourse = async (dispatch) => {
    await dispatch(getCourseStart());
    try {
        const res = await axios.get("/api/lark-data");
        await dispatch(getCourseSuccess(res.data));
    }
    catch (err) {
        await dispatch(getCoursesFailed());
    }
}
export const getAllLecturer = async (dispatch) => {
    await dispatch(getLecturerStart());
    try {
        const res = await axios.get("/api/lark-data/lecturer");
        await dispatch(getLecturerSuccess(res.data));
    }
    catch (err) {
        await dispatch(getLecturersFailed());
    }
}